import React from "react";
import Seo from "../../components/shared/seo";
import Hero from "../../components/installationPage/hero";
import Where from "../../components/installationPage/where";
import Uninstall from "../../components/installationPage/uninstall";
import Layout from "../../components/shared/layout";
import { useIntl } from "gatsby-plugin-react-intl";

function Installation() {
  const intl = useIntl();

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({
          id: "InstructionsInstallationPageTitle",
        })}
        description={intl.formatMessage({
          id: "InstructionsInstallationPageDescription",
        })}
      />
      <Hero />
      <Where />
      <Uninstall />
    </Layout>
  );
}

export default Installation;
